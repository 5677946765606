import router from "@/router";

export default {
    template: `
    <button tabindex="-1" class="btn-action-production-progress" data-toggle="tooltip" :title="$t('ProductionList.ButtonOperatorMode')" data-trigger="hover" @click="() => {this.redirectOperatorMode(params)}">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 6.5V11.5H0V2H6V3H1V10.5H9.5V6.5H10.5ZM12 0.5H6.506L8.5235 2.5L5.035 6.035L6.449 7.449L9.9375 3.914L12 6V0.5Z" fill="#974900"/>
        </svg>
    </button>
`,
    methods: {
        redirectOperatorMode(params) {
            const { operationId, resource } = params.data;
            const firstResourceId = resource?.[0]?.id ?? null;

            if (firstResourceId === null) {
                return;
            }

            router.push({
                name: "operator-production-progress",
                params: {
                    resource_id: firstResourceId,
                    order_id: operationId,
                    return_orders: true,
                    multiple: "false"
                }
            });
        }
    }
};
